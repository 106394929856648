import axios from "axios";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Textarea,
  useDisclosure,
  Image,
} from "@nextui-org/react";

import React, { useContext, useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { AppContext } from "../App";
import loadingIcon from "../images/loadingicon2.gif";
import { MdOutlineRateReview } from "react-icons/md";
import apiEndpoints from "../endpoints";

const TestimonialForm = ({ chatObj, isMobile }) => {
  const context = useContext(AppContext);
  const { renderTestimonial, setRenderTestimonial } = context;

  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [video, setVideo] = useState("");
  const [imgId, setImgId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSelected, setIsSelected] = React.useState(true);
  const [showLoading, setShowLoading] = React.useState(false);
  const [submittedReview, setSubmittedReview] = React.useState(false);
  const [isValidName, setIsValidName] = useState(true);
  const [isValidLocation, setIsValidLocation] = useState(true);
  const [isValidVideo, setIsValidVideo] = useState(true);
  const [isValidComment, setIsValidComment] = useState(false);
  const [imgSuccessMessage, setImgSuccessMessage] = useState("");
  const [imgUploading, setImgUploading] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const validateName = (name) => {
    return /^[A-Za-z\s]+$/.test(name);
  };
  const validateLocation = (location) => {
    return /^[A-Za-z0-9\s!@#$%^&*()-_+=\[\]{}|;:'",.<>/?]+$/g.test(location);
  };

  const validateVideoLink = (link) =>
    /^(https?:\/\/)?(?:www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/.test(
      link
    );
  const validateComment = (text) => {
    if (text.length > 400) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(
    () => {
      if (renderTestimonial) {
        onOpen();
      }
    },
    [renderTestimonial]
  );

  useEffect(
    () => {
      if (!selectedFile) {
        setImgSuccessMessage("");
      }
    },
    [selectedFile]
  );

  const handleChange = (event) => {
    if (event.target.name === "name") {
      setName(event.target.value);
      if (event.target.value) {
        setIsValidName(validateName(event.target.value));
      } else {
        setIsValidName(true);
      }
    } else if (event.target.name === "location") {
      setLocation(event.target.value);
      if (event.target.value) {
        setIsValidLocation(validateLocation(event.target.value));
      } else {
        setIsValidLocation(true);
      }
    } else if (event.target.name === "video") {
      setVideo(event.target.value);
      if (event.target.value) {
        setIsValidVideo(validateVideoLink(event.target.value));
      } else {
        setIsValidVideo(true);
      }
    } else if (event.target.name === "comment") {
      setComment(event.target.value);
      if (event.target.value) {
        setIsValidComment(validateComment(event.target.value));
      } else {
        setIsValidComment(false);
      }
    }
  };

  const handleSubmit = async () => {
    // onClose();
    let resp = null;

    setShowLoading(true);
    // console.log("full payload", {
    //   name: name,
    //   location: location,
    //   // profileImg: {formData},
    //   reviewText: comment,
    //   showInTestimonial: isSelected,
    // });

    const dataPayload = {
      name: name || "Anonymous",
      location: location || "Anonymous",
      reviewText: comment,
      showInTestimonial: isSelected,
      img_serialid: imgId,
      video_url: video,
    };
    try {
      resp = await axios.post(`${apiEndpoints.saveTestimonial}`, dataPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (resp.status === 200) {
        setComment("");
        setName("");
        setLocation("");
        setVideo("");
        setImgId("");
        setFileUpload(false);
        setSelectedFile(null);
        setIsSelected(true);
        setShowLoading(false);
        setSubmittedReview(true);
      }
    } catch (error) {
      console.log(error.message);
      setShowLoading(false);
      onClose();
    }
  };
  const handleCancel = () => {
    onClose();
    setRenderTestimonial(false);
    setComment("");
    setSubmittedReview(false);
    setComment("");
    setName("");
    setLocation("");
    setVideo("");
    setImgId("");
    setFileUpload(false);
    setSelectedFile(null);
    setIsSelected(true);
    setShowLoading(false);
    setIsValidName(true);
    setIsValidLocation(true);
    setIsValidVideo(true);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileUpload(true);
    setImgSuccessMessage("");
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    setImgUploading(true);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
    let resp = null;
    try {
      resp = await axios.post(`${apiEndpoints.uploadProfileImage}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (resp.status === 200) {
        setImgSuccessMessage(resp.data.message);
        setImgId(resp.data.img_serialid);
        setImgUploading(false);
        setFileUpload(false);
      }
    } catch (error) {
      resp = error.message;
      setImgUploading(false);
      setFileUpload(false);
    }
  };

  return (
    <div className="z-[300] w-full">
      {isMobile ? (
        <button
          onClick={onOpen}
          className="p-0 w-fit h-fit bg-transparent text-[30px] flex "
        >
          <MdOutlineRateReview />
        </button>
      ) : (
        <Button
          className={`redHatSemiBold min-h-fit h-fit w-full px-[15px] py-[10px] bg-[#edededde] text-black border border-none rounded-xl`}
          onPress={onOpen}
        >
          Leave a Review
        </Button>
      )}
      <Modal
        size="sm"
        placement="center"
        classNames={{
          backdrop: ["z-[300]"],
          wrapper: ["z-[300]", "modalStyle"],
        }}
        className=""
        isOpen={isOpen}
        onClose={handleCancel}
        hideCloseButton={showLoading}
        isDismissable={false}
      >
        {showLoading ? (
          <ModalContent className="w-full h-[300px] flex items-center justify-center">
            <Image src={loadingIcon} alt="loading" width={70} />
          </ModalContent>
        ) : submittedReview ? (
          <ModalContent>
            {(onClose) => (
              <>
                <ModalBody>
                  <FaCheckCircle
                    color={"green"}
                    size={"30px"}
                    className="w-full items-center text-center mt-[30px]"
                  />
                  <h3 className="text-[18px] redHatBold font-bold text-center">
                    Your testimonial saved successfully!
                  </h3>
                  <h3 className="text-[14px] redHatBold font-bold text-center">
                    Thanks for your valuable comments.
                  </h3>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="success"
                    variant="light"
                    onPress={handleCancel}
                    className="bg-gray-300 text-black font-semibold"
                  >
                    Close
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        ) : (
          <ModalContent>
            {(onClose) => (
              <>
                <ModalBody>
                  <h2 className="text-[18px] redHatBold font-bold text-center">
                    Submit Testimonial
                  </h2>
                  <Textarea
                    isInvalid={isValidComment}
                    labelPlacement="outside"
                    label="Your experience with Prach"
                    name="comment"
                    value={comment}
                    onChange={handleChange}
                    placeholder="Please share your thoughts and suggestions..."
                    // errorMessage="The description should be less than 400 characters."
                  />
                  {isValidComment && (
                    <p style={{ color: "red" }} className="text-[14px]">
                      The description should be less than 400 characters.
                    </p>
                  )}
                  {/* Name  */}
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    label="Name (optional)"
                    placeholder="Please enter your full name"
                    labelPlacement="outside"
                    style={{ borderColor: isValidName ? "initial" : "red" }}
                  />
                  {!isValidName && (
                    <p style={{ color: "red" }} className="text-[14px]">
                      Please enter a valid name (letters and spaces only).
                    </p>
                  )}
                  {/* location */}
                  <Input
                    type="text"
                    name="location"
                    value={location}
                    onChange={handleChange}
                    label="Location (optional)"
                    placeholder="eg. Mumbai, India"
                    labelPlacement="outside"
                  />
                  {!isValidLocation && (
                    <p style={{ color: "red" }} className="text-[14px]">
                      Please enter a valid location.
                    </p>
                  )}
                  <Input
                    type="text"
                    name="video"
                    value={video}
                    onChange={handleChange}
                    label="Video review (optional)"
                    placeholder="eg. https://www.youtube.com/review_on_prach"
                    labelPlacement="outside"
                  />
                  {!isValidVideo && (
                    <p style={{ color: "red" }} className="text-[14px]">
                      Please enter a valid youtube link.
                    </p>
                  )}
                  <div className="text-[15px]">Profile Image (optional)</div>
                  <div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="w-full mb-2"
                      accept="image/*"
                    />
                    {imgSuccessMessage ? (
                      <span className="text-green-600">
                        {imgSuccessMessage}
                      </span>
                    ) : (
                      <Button
                        className="bg-black text-white mt-2"
                        onClick={handleUpload}
                        isLoading={imgUploading}
                        spinner={
                          <svg
                            className="animate-spin h-5 w-5 text-current"
                            fill="none"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="opacity-75"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              fill="currentColor"
                            />
                          </svg>
                        }
                      >
                        {imgUploading ? "Uploading" : "Upload"}
                      </Button>
                    )}
                    <span />
                  </div>
                  <Checkbox
                    className="mt-3"
                    size="sm"
                    isSelected={isSelected}
                    onValueChange={setIsSelected}
                  >
                    Uncheck this box if you prefer to keep your review private.
                  </Checkbox>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    variant="light"
                    onPress={handleCancel}
                    className="text-black font-semibold"
                  >
                    Cancel
                  </Button>
                  <Button
                    isDisabled={
                      !comment ||
                      isValidComment ||
                      !isValidName ||
                      !isValidLocation ||
                      !isValidVideo ||
                      fileUpload
                    }
                    // onPress={onClose}
                    onClick={handleSubmit}
                    className="bg-gray-300 font-semibold"
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        )}
      </Modal>
    </div>
  );
};

export default TestimonialForm;
