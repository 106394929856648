import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Tooltip,
} from "@nextui-org/react";
import { googleLogout } from "@react-oauth/google";
import React, { useContext, useEffect, useState } from "react";
import { BiDonateHeart } from "react-icons/bi";
import { FaHeart } from "react-icons/fa";
import { IoIosArrowDown, IoIosPaper } from "react-icons/io";
import { MdOutlineSubscriptions } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../App";
import leftArrowBold from "../images/left-arrow-bold.svg";
import loginImg from "../images/login.svg";
import loadingLogo from "../images/logo-without-name.svg";
import logout from "../images/logout.svg";
import myAccount from "../images/my-account.svg";
import privacyPolicy from "../images/privacy-policy.svg";
import rightArrowBold from "../images/right-arrow-bold.svg";
import termsOfUse from "../images/terms-of-use.svg";
import wpCommunity from "../images/wp-community.svg";
import { sendLogoutData } from "./services";
import TestimonialForm from "./TestimonialForm";

const Header = ({ className }) => {
  const context = useContext(AppContext);
  const {
    currentPage,
    signerData,
    setChatHistory,
    setSignerData,
    setChatResponse,
    setIframeData,
    renderTestimonial,
    setRenderTestimonial,
  } = context;
  const navigate = useNavigate();

  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleScroll = () => {
    const isScrolled = window.scrollY > 15;
    setScrolled(isScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logOut = async () => {
    googleLogout();
    await sendLogoutData(signerData?.id);
    setChatHistory([]);
    setSignerData([]);
    setChatResponse([]);
    localStorage.setItem("prachQuesCount", 0);
    localStorage.setItem("login_token", "");
    navigate(`/`);
    setIsMenuOpen(false);
  };

  const openTermsOfUse = () => {
    window.open("documents/Prach Terms of Service.pdf", "_blank");
    setIsMenuOpen(false);
  };

  const loginBtnRender = (isMobile) => {
    return (
      <>
        {currentPage === "login" ? (
          <Button
            onClick={() => {
              navigate("/");
              setIsMenuOpen(false);
            }}
            className="bg-transparent p-0 text-[14px] md:text-[16px] leading-[21px] redHatMedium"
            startContent={
              <img src={leftArrowBold} alt="dicover world of autism" />
            }
          >
            Discover the world of Autism
          </Button>
        ) : (
          <>
            {Object.keys(signerData).length > 0 && signerData?.email ? (
              <Dropdown
                showArrow
                placement={`${isMobile}?"top-end":right-start`}
                className=""
                classNames={{
                  base: "before:bg-default-200 ",
                  content: "shadow-md",
                }}
              >
                <DropdownTrigger>
                  <span className="flex flex-col gap-1 px-4 py-1 hover:shadow-lg hover:rounded-[12px]">
                    <span className="flex gap-1 cursor-pointer">
                      <span className="w-[20px] h-[20px] bg-[#4A4A4A] rounded-full flex justify-center items-center">
                        <img
                          src={signerData?.picture}
                          alt="login user"
                          className={`w-[20px] h-[20px] rounded-full p-0`}
                        />
                      </span>
                      <span className="redHatSemiBold text-[#000000] text-[16px] leading-[19px] flex gap-2 items-center justify-center">
                        {signerData?.name}
                        <img src={rightArrowBold} alt="user details" />
                      </span>
                    </span>
                  </span>
                </DropdownTrigger>
                <DropdownMenu aria-label="Dropdown menu with description">
                  <DropdownItem
                    key="my-account"
                    className="flex redHatMedium text-[14px]"
                    startContent={<img src={myAccount} alt="" />}
                  >
                    My Account
                  </DropdownItem>
                  <DropdownItem
                    key="ap-community"
                    className="flex redHatMedium text-[14px]"
                    startContent={<img src={wpCommunity} alt="" />}
                    onClick={() => {
                      window.open(
                        "https://whatsapp.com/channel/0029Vakrd3zAojYzJD9oeX0O",
                        "_blank"
                      );
                      setIsMenuOpen(false);
                    }}
                  >
                    WhatsApp Community
                  </DropdownItem>
                  <DropdownItem
                    key="terms-of-use"
                    className="flex redHatMedium text-[14px]"
                    startContent={<img src={termsOfUse} alt="" />}
                    onClick={openTermsOfUse}
                  >
                    Terms of use
                  </DropdownItem>
                  <DropdownItem
                    key="privacy-policy"
                    className="flex redHatMedium text-[14px]"
                    startContent={<img src={privacyPolicy} alt="" />}
                  >
                    Privacy Policy
                  </DropdownItem>
                  <DropdownItem
                    key="logout"
                    className="flex redHatMedium text-[14px] border border-t-1 border-l-0 border-r-0 border-b-0"
                    startContent={<img src={logout} alt="" />}
                    onClick={logOut}
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : (
              <Tooltip
                color="warning"
                closeDelay={5000}
                content="Login for better experience"
                className="bg-white text-[12px] rounded-md"
              >
                <Button
                  onClick={() => {
                    navigate("/login/?page=login");
                    setIsMenuOpen(false);
                  }}
                  // className="bg-transparent p-0  redHatMedium text-[14px] md:text-[16px] text-[#000000] leading-[21px]"
                  className={`redHatSemiBold ${
                    isMobile
                      ? "w-full rounded-none bg-[#edededde] font-bold"
                      : "min-h-fit h-fit px-[15px] py-[10px] bg-[#1859c9] text-white  border-[#DDDDDD] shadow-lg rounded-xl"
                  }`}
                  startContent={
                    <Image
                      src={loginImg}
                      alt="login image"
                      className={`h-[18px] w-[18px] rounded-none sm:text-white color-[#fff] ${
                        isMobile ? "hidden" : "visible"
                      }`}
                    />
                  }
                >
                  Log in
                </Button>
              </Tooltip>
            )}
          </>
        )}
      </>
    );
  };

  const navLogin = (isMobile) => (
    <>
      {currentPage === "login" ? (
        <Button
          onClick={() => {
            navigate("/");
            setIsMenuOpen(false);
          }}
          className={`bg-transparent p-0 text-[14px] md:text-[16px] leading-[21px] redHatMedium`}
          startContent={
            <img src={leftArrowBold} alt="dicover world of autism" />
          }
        >
          Discover the world of Autism
        </Button>
      ) : (
        loginBtnRender(isMobile)
      )}
    </>
  );

  const navWorksheet = (isMobile, render) => (
    <>
      {render ? (
        <button
          onClick={() => {
            navigate("/worksheets");
            setIsMenuOpen(false);
          }}
          className="p-0 w-fit h-fit bg-transparent text-[30px] flex justify-end"
        >
          <IoIosPaper />
        </button>
      ) : (
        <Button
          onClick={() => {
            navigate("/worksheets");
            setIsMenuOpen(false);
          }}
          className={`redHatSemiBold ${
            isMobile
              ? "w-full rounded-none bg-[#edededde]"
              : " min-h-fit h-fit w-full px-[15px] py-[10px] bg-[#edededde] text-black border border-none rounded-xl"
          }`}
        >
          Worksheets
        </Button>
      )}
    </>
  );
  const navDonate = (isMobile, render) => (
    <>
      {render ? (
        <button
          onClick={() => {
            setIsMenuOpen(false);
            navigate("/donate");
          }}
          className="p-0 w-fit h-fit bg-transparent text-[30px] flex justify-end"
        >
          <BiDonateHeart />
        </button>
      ) : (
        <Button
          className={`redHatSemiBold ${
            isMobile
              ? "w-full rounded-none bg-[#edededde]"
              : "min-h-fit h-fit px-[15px] py-[10px] bg-[#ed143d] text-white border border-[#DDDDDD] shadow-lg rounded-xl"
          }`}
          onClick={() => {
            setIsMenuOpen(false);
            navigate("/donate");
          }}
          endContent={<FaHeart />}
        >
          Donate
        </Button>
      )}
    </>
  );
  const navSubscribe = (isMobile, render) => (
    <>
      {render ? (
        <button
          onClick={() => {
            setIsMenuOpen(false);
            navigate("/subscribe");
          }}
          className="p-0 w-fit h-fit bg-transparent text-[30px] flex justify-end"
        >
          <MdOutlineSubscriptions />
        </button>
      ) : (
        <Button
          className={`redHatSemiBold ${
            isMobile
              ? "w-full rounded-none bg-[#edededde]"
              : "min-h-fit h-fit px-[15px] py-[10px] bg-[#ed143d] text-white border border-[#DDDDDD] shadow-lg rounded-xl"
          }`}
          onClick={() => {
            setIsMenuOpen(false);
            navigate("/subscribe");
          }}
          endContent={<MdOutlineSubscriptions />}
        >
          Subscribe
        </Button>
      )}
    </>
  );

  const openIframe = (url) => {
    console.log("url", url);
    setIframeData({ url, visible: true });
  };

  // useEffect(() => {
  //   const menuButton = document.querySelector('[aria-label="Open menu"]');

  //   // Function to update visibility based on window width
  //   const updateButtonVisibility = () => {
  //     if (window.innerWidth >= 1200) {
  //       // Show button if window width is 1200px or more
  //       menuButton?.classList.remove("hidden");
  //       menuButton?.classList.add("visible");
  //     } else {
  //       // Hide button if window width is less than 1200px
  //       menuButton?.classList.remove("visible");
  //       menuButton?.classList.add("hidden");
  //     }
  //   };

  //   // Call the function initially
  //   updateButtonVisibility();

  //   // Add event listener to update visibility on resize
  //   window.addEventListener("resize", updateButtonVisibility);

  //   // Clean up event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener("resize", updateButtonVisibility);
  //   };
  // }, []);

  console.log("---->", document.querySelector('[aria-label="Open menu"]'));

  return (
    <Navbar
      onMenuOpenChange={setIsMenuOpen}
      isMenuOpen={isMenuOpen}
      className="navigation-header"
      classNames={{ menu: ["visible"] }}
    >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand
          className="cursor-pointer flex gap-1 items-center"
          onClick={() => navigate("/")}
        >
          <img src={loadingLogo} alt="loader prach logo" />
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>{navDonate()}</NavbarItem>
        <NavbarItem>{navSubscribe()}</NavbarItem>
        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          <Dropdown>
            <NavbarItem>
              <DropdownTrigger>
                <Button
                  disableRipple
                  className="p-0 bg-transparent data-[hover=true]:bg-transparent font-bold"
                  endContent={
                    <IoIosArrowDown className="text-[18px] pt-[2px]" />
                  }
                  radius="sm"
                  variant="light"
                >
                  More
                </Button>
              </DropdownTrigger>
            </NavbarItem>
            <DropdownMenu
              aria-label="ACME features"
              className="w-[340px]"
              itemClasses={{
                base: "gap-4",
              }}
            >
              <DropdownItem
                key="autoscaling"
                // description="ACME scales apps to meet user demand, automagically, based on load."
                // startContent={icons.scale}
              >
                {navWorksheet()}
              </DropdownItem>
              <DropdownItem
                key="usage_metrics"
                // description="Real-time metrics to debug issues. Slow query added? We’ll show you exactly where."
                // startContent={icons.activity}
              >
                <TestimonialForm isMobile={false} />
              </DropdownItem>
              <DropdownItem
                key="production_ready"
                // description="ACME runs on ACME, join us and others serving requests at web scale."
                // startContent={icons.flash}
              >
                <Button
                  className="min-h-fit h-fit font-bold w-full px-[15px] py-[10px] bg-[#edededde] text-black border border-none rounded-xl"
                  onPress={() =>
                    openIframe("https://voicegrid.prach.org/welcome")
                  }
                >
                  Voice Grid
                </Button>
              </DropdownItem>
              <DropdownItem
                key="99_uptime"
                // description="Applications stay on the grid with high availability and high uptime guarantees."
                // startContent={icons.server}
              >
                <Button
                  className="min-h-fit h-fit font-bold w-full px-[15px] py-[10px] bg-[#edededde] text-black border border-none rounded-xl"
                  onPress={() => openIframe("https://vamilio.vercel.app/")}
                >
                  Bloopa
                </Button>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarContent>
        {/* <NavbarItem>{navWorksheet()}</NavbarItem>
        <NavbarItem>
          <TestimonialForm isMobile={false} />
        </NavbarItem> */}
        {/* <NavbarItem>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => openIframe("https://voicegrid.prach.org/welcome")}
          >
            Voice Grid
          </button>
        </NavbarItem>
        <NavbarItem>
          <button
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 ml-2"
            onClick={() => openIframe("https://vamilio.vercel.app/")}
          >
            Vamilio
          </button>
        </NavbarItem> */}
      </NavbarContent>

      <NavbarContent justify="end">
        <div className="flex sm:hidden gap-5">
          {/* {navWorksheet(true, true)}
          {navSubscribe(true, true)}
          {navDonate(true, true)} */}
          <TestimonialForm isMobile={true} />
        </div>
        <NavbarItem>{navLogin()}</NavbarItem>
      </NavbarContent>
      {/* mobile */}
      <NavbarMenu className="z-[200]">
        <NavbarMenuItem>{navDonate(true, false)}</NavbarMenuItem>
        <NavbarMenuItem>{navSubscribe(true, false)}</NavbarMenuItem>
        <NavbarMenuItem>{navWorksheet(true)}</NavbarMenuItem>
        <NavbarMenuItem>
          <Button
            className={`redHatSemiBold w-full rounded-none bg-[#edededde]`}
            onPress={() => {
              setIsMenuOpen(false);
              openIframe("https://voicegrid.prach.org/welcome");
            }}
          >
            Voice Grid
          </Button>
        </NavbarMenuItem>
        <NavbarMenuItem>
          <Button
            className={`redHatSemiBold w-full rounded-none bg-[#edededde]`}
            onPress={() => {
              setIsMenuOpen(false);
              openIframe("https://vamilio.vercel.app/");
            }}
          >
            Bloopa
          </Button>
        </NavbarMenuItem>
        <NavbarMenuItem>{navLogin(true)}</NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
};

export default Header;
