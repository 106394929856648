export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiEndpoints = {
  handleUserPrompt: `${BASE_URL}/handle_user_prompt`,
  generateAlternativeQuestions: `${BASE_URL}/generate-alternative-questions`,
  uploadProfileImage: `${BASE_URL}/upload_profile_image`,
  saveTestimonial: `${BASE_URL}/save_testimonial`,
  submitFeedback: `${BASE_URL}/submit_feedback`,
  createAuditRecord: `${BASE_URL}/api/create_audit_record`,
  updateAuditRecord: `${BASE_URL}/api/update_audit_record`,
  retrieveResponse: `${BASE_URL}/retrieve_response`,
  fetchTestimonials: `${BASE_URL}/fetchtestimonials`,
  createDonateSession: `${BASE_URL}/create_donate_session`,
  createCheckoutLink: `${BASE_URL}/create_checkout_link`,
};

export default apiEndpoints;
