import axios from "axios";
import { BASE_URL } from "../../endpoints";

class WorksheetServices {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async getAllCategories() {
    try {
      const response = await this.api.get("/api/get_all_categories", {
        // headers: {
        //   Authorization: "Bearer your_token_here",
        // },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  }
  async getAllResources(page = 1, perPage = 10) {
    try {
      const response = await this.api.post("/api/get_all_resources", {
        page,
        per_page: perPage,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching resources:", error);
      throw error;
    }
  }

  async getResourceByCategory(tags) {
    try {
      const response = await this.api.post("/api/search_resources", {
        page: 1,
        per_page: 10,
        tags: tags,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching getResourceByCategory:", error);
      throw error;
    }
  }
}

// const worksheetServices = new WorksheetServices("");
const worksheetServices = new WorksheetServices(BASE_URL);

export default worksheetServices;
