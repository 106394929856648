import { AppProvider, SubscriptionApp } from "panna-billings-module";
import React, { useContext } from "react";
import { AppContext } from "../../App";

const Subscription = () => {
  const context = useContext(AppContext);
  const { signerData } = context;
  const API_BASE_URL = `${process.env.REACT_APP_DEV_DOMAIN}/api`;
  return (
    <div className="flex justify-center items-center flex-col">
      <AppProvider>
        <SubscriptionApp
          domainUrl={API_BASE_URL}
          signerData={signerData}
          stripeKey={process.env.REACT_APP_TEST_STRIPE_PUBLIC_KEY_SUB}
        />
      </AppProvider>
    </div>
  );
};

export default Subscription;
