import React from "react";
import { Link } from "@nextui-org/react";

const Disclaimer = () => {
  return (
    <div className="flex my-[60px] px-[10%] items-center justify-center">
      <div className="flex flex-col gap-5">
        <div>
          <b>Disclaimer</b>
          <br />
          <br />
          <b>General Information</b>
          <br />
          <br />
          The information provided by Prach we on{" "}
          <Link href="https://www.prach.org" target="_blank">
            https://www.prach.org
          </Link>{" "}
          is for general informational purposes only. All information on the
          Site is provided in good faith, however, we make no representation or
          warranty of any kind, express or implied, regarding the accuracy,
          adequacy, validity, reliability, availability, or completeness of any
          information on the Site.
          <br />
          <br />
          <b>Professional Advice</b>
          <br />
          <br />
          The Site cannot and does not contain [legal/medical/financial/etc.]
          advice. The [legal/medical/financial/etc.] information is provided for
          general informational and educational purposes only and is not a
          substitute for professional advice. Accordingly, before taking any
          actions based upon such information, we encourage you to consult with
          the appropriate professionals. We do not provide any kind of
          [legal/medical/financial/etc.] advice. The use or reliance of any
          information contained on this Site is solely at your own risk.
          <br />
          <br />
          <b>External Links</b>
          <br />
          <br />
          The Site may contain links to other websites or content belonging to
          or originating from third parties or links to websites and features in
          banners or other advertising. Such external links are not
          investigated, monitored, or checked for accuracy, adequacy, validity,
          reliability, availability, or completeness by us. We do not warrant,
          endorse, guarantee, or assume responsibility for the accuracy or
          reliability of any information offered by third-party websites linked
          through the Site or any website or feature linked in any banner or
          other advertising. We will not be a party to or in any way be
          responsible for monitoring any transaction between you and third-party
          providers of products or services.
          <br />
          <br />
          <b>Affiliates Disclaimer</b>
          <br />
          <br />
          The Site may contain links to affiliate websites, and we receive an
          affiliate commission for any purchases made by you on the affiliate
          website using such links.
          <br />
          <br />
          <b>Testimonials Disclaimer</b>
          <br />
          <br />
          The Site may contain testimonials by users of our products and/or
          services. These testimonials reflect the real-life experiences and
          opinions of such users. However, the experiences are personal to those
          particular users, and may not necessarily be representative of all
          users of our products and/or services. We do not claim, and you should
          not assume, that all users will have the same experiences. Your
          individual results may vary.
          <br />
          <br />
          <b>Errors and Omissions</b>
          <br />
          <br />
          While we have made every attempt to ensure that the information
          contained in this site has been obtained from reliable sources, Prach
          is not responsible for any errors or omissions or for the results
          obtained from the use of this information. All information in this
          site is provided "as is", with no guarantee of completeness, accuracy,
          timeliness, or of the results obtained from the use of this
          information, and without warranty of any kind, express or implied,
          including, but not limited to warranties of performance,
          merchantability, and fitness for a particular purpose.
          <br />
          <br />
          <b>Limitation of Liability</b>
          <br />
          <br />
          In no event will Prach, its related partnerships or corporations, or
          the partners, agents, or employees thereof be liable to you or anyone
          else for any decision made or action taken in reliance on the
          information in this Site or for any consequential, special, or similar
          damages, even if advised of the possibility of such damages.
          <br />
          <br />
          <b>Contact Us</b>
          <br />
          <br />
          If you have any questions about this Disclaimer, you can contact us
          at:
          <br />
          <br />
          <Link href="mailto:prachaibot@gmail.com">prachaibot@gmail.com</Link>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
