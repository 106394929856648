import { Divider, Image } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../App";
import ph_logo from "../images/PH-Logo.png";
import eduQueries_logo from "../images/eduqueries-logo.jpg";
import hlth_logo from "../images/hlth-logo.png";
import logo from "../images/logo.svg";
import { cn } from "../utils/cn";
import { specialNeedsServices } from "../utils/specialNeedUses";
import { suggestedCardsData } from "../utils/suggestionCardsData";
import { handleSearch } from "../utils/utilityFunctions";
import ChatInput from "./ChatInput";
import EmblaCarousel from "./EmblaCarousel";
import SpecialNeedsServiceCard from "./SpecialNeedsServiceCard";
import SuggestionCards from "./SuggestionCards";
import VideoSlider from "./VideoSlider";
import { fetchTestimonials } from "./services";

const HomePage = ({ className }) => {
  const context = useContext(AppContext);
  const {
    setCurrentPage,
    setActiveFaq,
    setIsOpenFaqAccordion,
    signerData,
  } = context;
  const navigate = useNavigate();
  const [showMoreCards, setShowMoreCards] = useState(false);

  useEffect(
    () => {
      setCurrentPage("home");
    },
    [setCurrentPage]
  );

  // useEffect(() => {
  //   if (Object.keys(signerData).length > 0 && signerData?.verified_email) {
  //     navigate("/results");
  //   }
  // }, [navigate, signerData]);

  const handleCardClick = (e) => {
    let prompt = e.currentTarget.name;
    setActiveFaq(prompt);
    // setIsOpenFaqAccordion(true);
    handleSearch(context, navigate, prompt);
  };
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const getTestimonials = async () => {
      let testimonialsData = await fetchTestimonials();
      setTestimonials(testimonialsData?.data?.testimonials);
    };
    getTestimonials();
  }, []);

  useEffect(() => {
    let element = null;
    if (isMobile) {
      element = document.getElementById("mainChatMobile");
    } else {
      element = document.getElementById("mainChatDesktop");
    }
    if (element) {
      element.focus();
    }
  }, []);

  const [swiperRef, setSwiperRef] = useState(null);

  const options = {
    showArrows: true, // Enable navigation arrows
    autoPlay: true,
    infiniteLoop: true, // Loop through slides infinitely
    stopOnHover: true, // Pause autoplay on hover
    swipeable: true, // Enable swipe navigation on touch devices
    showThumbs: false,
    // slidesPerView: 3,
    showIndicators: false,
    showStatus: false,
    interval: 3000,
  };

  const openWebsite = (link) => {
    window.open(link, "_blank");
  };
  return (
    <main className={cn("flex flex-col justify-between", className)}>
      <div className="flex flex-col items-center gap-5 md:gap-9 mt-8 md:mt-11 ">
        <div className="flex flex-col items-center justify-center">
          <img
            src={logo}
            alt="Prach Logo"
            className="rounded-none w-[70px] h-[70px] md:w-[120px] md:h-[120px]"
          />
        </div>
        <h2 className="text-2xl font-bold text-center redHatBold text-[16px] md:text-[22px] leading-[20px] md:leading-[43px] w-[60%]">
          Neurodiverse Companion
        </h2>
        {/* <AdsenseExample/> */}
        <ChatInput className="w-full md:w-[70%]" />
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {specialNeedsServices.map((service) => (
            <SpecialNeedsServiceCard key={service.id} {...service} />
          ))}
        </div> */}
        <div className="carousel-container w-[100vw]">
          <Carousel ref={swiperRef} {...options}>
            {[
              ...Array(
                Math.ceil(
                  specialNeedsServices.length /
                    (isMobile ? 1 : isTablet ? 2 : 3)
                )
              ),
            ].map((_, index) => (
              <div
                className="flex justify-center items-center gap-[15px] mb-[5px]"
                key={index}
              >
                {specialNeedsServices
                  .slice(
                    index * (isMobile ? 1 : isTablet ? 2 : 3),
                    (index + 1) * (isMobile ? 1 : isTablet ? 2 : 3)
                  )
                  .map((service) => (
                    <SpecialNeedsServiceCard key={service.id} {...service} />
                  ))}
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      {/* <div className="mt-[30px] sm:mt-[40px]" /> */}
      {testimonials && testimonials?.length > 0 && (
        <>
          <EmblaCarousel testimonials={testimonials} />
          <VideoSlider />
        </>
      )}
      {/* <div className="flex flex-col items-center gap-4 mt-[30px]">
        <div className="text-[18px] md:text-[20px] leading-[31px] text-[#000000] redHatBold mt-6">
          Videos on Autism
        </div> */}
      {/* </div> */}
      <div className="flex flex-col items-center gap-4 mt-[30px]">
        <div className="text-[18px] md:text-[20px] leading-[31px] text-[#000000] redHatBold mt-6">
          Frequently Asked Questions
        </div>
        <div className="gap-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
          {suggestedCardsData
            .slice(0, showMoreCards ? 16 : 4)
            .map((card, i) => {
              const { title, subTitle, iconSrc } = card;
              return (
                <SuggestionCards
                  handleCardClick={handleCardClick}
                  key={i + { title }}
                  title={title}
                  subTitle={subTitle}
                  iconSrc={iconSrc}
                />
              );
            })}
        </div>
      </div>
      {/* <div className="flex flex-col items-center gap-4 mt-[60px]"> */}

      {/* </div> */}

      <Divider className="my-9" />
      <div className="neomorphism">
        <div className="w-full text-[24px] sm:text-[32px] redHatBold text-center leading-[1]">
          <span className="gradient-border">
            Our <span className="text-[#FF0080]">Partners</span>
          </span>
        </div>
        <div className="flex justify-center mt-14">
          <div className="grid grid-cols-2 md:grid-cols-2  gap-6 sm:gap-14">
            {/* <Image
              src={ph_logo}
              alt="ph logo"
              className="h-[80px] w-[140px] rounded-none cursor-pointer"
              onClick={() => openWebsite("https://pranickolkata.com/")}
            /> */}
            <Image
              src={hlth_logo}
              alt="hlth logo"
              className="h-[80px] w-[140px] rounded-none cursor-pointer"
              onClick={() => openWebsite("https://www.hlthtek.com/")}
            />
            <Image
              src={eduQueries_logo}
              alt="hlth logo"
              className="h-[80px] w-[120px] rounded-none cursor-pointer"
              onClick={() => openWebsite("https://edqueries.com/")}
            />
            {/* <span
              className="cursor-pointer"
              onClick={() => openWebsite("https://findingtrustedcare.com/")}
            >
              {findingTrustedCare()}
            </span> */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default HomePage;
