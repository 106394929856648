import axios from "axios";
import apiEndpoints from "../endpoints";

export const submitFeedback = async (data) => {
  let resp = null;
  try {
    resp = await axios.post(`${apiEndpoints.submitFeedback}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    resp = error.message;
  }
  return resp;
};

export const sendLoginData = async (data) => {
  let resp = null;
  try {
    resp = await axios.post(`${apiEndpoints.createAuditRecord}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    resp = error.message;
  }
  return resp;
};

export const sendLogoutData = async (id) => {
  let resp = null;
  try {
    resp = await axios.post(`${apiEndpoints.updateAuditRecord}/${id}`);
  } catch (error) {
    resp = error.message;
  }
  return resp;
};

export const getIdResult = async (data) => {
  let resp = null;
  try {
    resp = await axios.post(`${apiEndpoints.retrieveResponse}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    resp = error.message;
  }
  return resp;
};

export const fetchTestimonials = async () => {
  let resp = null;
  try {
    resp = await axios.get(`${apiEndpoints.fetchTestimonials}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    resp = error.message;
  }
  return resp;
};
