import { Button } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import likeOutlined from "../images/like-outline.svg";
import disLikeOutlined from "../images/dislike-outline.svg";
import Feedback from "./Feedback";
import rightArrowBold from "../images/right-arrow-bold.svg";
import { AppContext } from "../App";
import { handleSearch } from "../utils/utilityFunctions";
import { useNavigate } from "react-router-dom";

const AnswerComponent = ({ answer, obj }) => {
  const context = useContext(AppContext);
  const { setActiveFaq } = context;
  const navigate = useNavigate();
  const lastWordStringIndex = answer.substring(0, obj.viewed);
  const [displayedText, setDisplayedText] = useState("");
  const checkAnchorTagInText = (text, extract) => {
    // Check if we're cutting off inside an <a> tag
    const lastOpenTag = extract.lastIndexOf("<a");
    const lastCloseTag = extract.lastIndexOf("</a>");

    // If there's an opening tag after the last closing tag, we're cutting off inside a tag
    if (lastOpenTag > lastCloseTag) {
      // Find the end of the <a> tag in the original text
      const endOfTag = text.indexOf("</a>", lastOpenTag);

      // If endOfTag is not found, something went wrong, return the initial extract
      if (endOfTag === -1) {
        return extract;
      }

      // Adjust the extract to include the full <a> tag
      extract = text.substring(0, endOfTag + 4);
    }
    return extract;
  };
  useEffect(
    () => {
      // setDisplayedText(answer.substring(0, lastWordStringIndex.lastIndexOf(" ")));
      setDisplayedText(
        checkAnchorTagInText(
          answer,
          answer.substring(0, lastWordStringIndex.lastIndexOf(" "))
        )
      );
    },
    [answer]
  );
  const [textLength, setTextLength] = useState(
    lastWordStringIndex.lastIndexOf(" ")
  );
  const [showMore, setShowMore] = useState(answer.length > 999);
  const handleShowMore = () => {
    const nextViewed = textLength + 1000;
    const substring = answer.substring(0, nextViewed);
    const lastSpaceIndexInString = substring.lastIndexOf(" ");

    if (answer.length - nextViewed < 100) {
      // setDisplayedText(answer.substring(0, answer.length));
      setDisplayedText(
        checkAnchorTagInText(answer, answer.substring(0, answer.length))
      );
      setShowMore(false);
      setTextLength(answer.length);
    } else {
      // setDisplayedText(answer.substring(0, lastSpaceIndexInString));
      setDisplayedText(
        checkAnchorTagInText(
          answer,
          answer.substring(0, lastSpaceIndexInString)
        )
      );
      setShowMore(displayedText.length < answer.length);
      setTextLength(lastSpaceIndexInString);
    }
  };

  const handleCardClick = (e) => {
    let prompt = e.target.name;
    setActiveFaq(prompt);
    handleSearch(context, navigate, prompt);
  };

  return (
    <div>
      <Markdown rehypePlugins={[rehypeRaw]}>{displayedText}</Markdown>
      {showMore && (
        <>
          {/* <div className="flex gap-3 mt-[15px] mb-[6px]">
            <img src={likeOutlined} alt="like" className="cursor-pointer" />
            <img
              src={disLikeOutlined}
              alt="dislike"
              className="cursor-pointer"
            />
          </div> */}
          <Button
            className="redHatSemiBold min-h-fit h-fit px-[15px] py-[10px] bg-white shadow-md border border-[#DDDDDD] mt-[10px]"
            onClick={handleShowMore}
          >
            Show More
            <sapn className="flex">
              <div
                className={"w-[5px] h-[5px] rounded-full bg-[#ff659b] mr-[2px]"}
              />
              <div
                className={"w-[5px] h-[5px] rounded-full bg-[#52e3ff] mr-[2px]"}
              />
              <div
                className={"w-[5px] h-[5px] rounded-full bg-[#a3a3a3] mr-[2px]"}
              />
            </sapn>
          </Button>
        </>
      )}
      <Feedback chatObj={obj} />
      <div className="mt-[10px]">
        <div className="redHatBold">Related Questions:</div>
        {obj.relatedQuestions.map((relQues) => {
          return (
            <div className="flex gap-[15px] border border-[#a3a3a4] border-b-1 border-t-0 border-l-0 border-r-0 py-2">
              <img src={rightArrowBold} alt="right arrow" />
              <button
                className="text-left"
                name={relQues}
                onClick={handleCardClick}
              >
                {relQues}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnswerComponent;
