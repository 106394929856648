import React from "react";
import { Link } from "@nextui-org/react";

const AboutUs = () => {
  return (
    <div className="flex h-[70vh] px-[10%] items-center justify-center">
      <div className="text-center flex flex-col gap-5 redHatBold">
        <div>
          At Prach AI, we specialize in providing innovative solutions for
          autistic parents, caregivers, and voices. Our platform leverages
          advanced AI technology to address questions and offer support, making
          it an excellent resource for professionals like BCBA, SLP, and OT
          therapists.
        </div>
        <div>
          Our website is{" "}
          <Link href="https://www.prach.org" target="_blank">
            https://www.prach.org
          </Link>
          .
        </div>
        <div>
          You can also follow our updates on{" "}
          <Link
            href="https://in.linkedin.com/company/prach-ai-autism"
            target="_blank"
          >
            https://in.linkedin.com/company/prach-ai-autism
          </Link>
        </div>
        <div>
          Given your expertise, we would be honored to have you join us for a
          webinar on our Prach AI YouTube channel{" "}
          <Link href="https://www.youtube.com/@PRACH-cj9lp" target="_blank">
            https://www.youtube.com/@PRACH-cj9lp
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
