import { Button } from "@nextui-org/react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import apiEndpoints from "../endpoints";

const DonationButton = ({ amount, selectedAmount, onClick }) => {
  return (
    <button
      className={`redHatSemiBold min-h-fit h-fit px-[15px] sm:px-[25px] py-[10px] border border-[#DDDDDD] shadow-lg ${
        selectedAmount === amount
          ? "bg-black text-white"
          : "bg-white text-black"
      }`}
      onClick={() => onClick(amount)}
    >
      $ {amount}
    </button>
  );
};

const DonationForm = () => {
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const context = useContext(AppContext);
  const { signerData, checkLogin } = context;

  const [mode, setMode] = useState("");
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get("stripemode");
    setMode(query);
  }, []);

  const handleButtonClick = (amount) => {
    setSelectedAmount(amount);
    setCustomAmount("");
  };

  const handleCustomInputChange = (event) => {
    setSelectedAmount(null);
    setCustomAmount(event.target.value);
  };

  const stripe_public_key =
    mode === "test"
      ? process.env.REACT_APP_TEST_STRIPE_PUBLIC_KEY
      : process.env.REACT_APP_PROD_STRIPE_PUBLIC_KEY;

  const stripePromise = loadStripe(stripe_public_key);
  const handleCustomAmountSubmit = async () => {
    setLoading(true);
    // https://www.pannalabs.ai:5001/create_donate_session
    const amount = parseFloat(customAmount) || selectedAmount;
    if (!isNaN(amount) && amount > 0) {
      try {
        const stripe = await stripePromise;
        const response = await axios.post(
          `${apiEndpoints.createDonateSession}`,

          { amount: selectedAmount || Number(customAmount) }
        );

        if (response?.data && response?.data?.id) {
          const { error } = await stripe.redirectToCheckout({
            sessionId: response.data.id,
          });
          if (error) {
            console.log("error", error.message);
          }
        } else {
          console.error("Invalid session ID:", response.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      // Handle invalid input, such as displaying an error message
      console.log("Invalid amount");
    }
  };

  const handleSubscription = (subPlan) => {
    setSelectedPlan(subPlan);
  };

  const handleSubscriptionSubmit = () => {
    let data = {
      customerEmailId: "peterParker@abc.com",
      subPlan: selectedPlan,
    };
    fetch(`${apiEndpoints.createCheckoutLink}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedPlan("");
        window.location.replace(data.url);
      });
  };

  return (
    <div className="h-[90vh] w-screen flex justify-center items-center">
      <div className="flex flex-col items-center">
        <div className="w-[90vw] sm:w-[50vw] text-[16px] sm:text-[18px] lg:text-[22px] text-center mb-10 redHatBold">
          Your donation fuels our mission to create a supportive space,
          providing vital resources and empowerment through AI assistance -
          Prach.
        </div>
        <div className="flex space-x-4">
          <DonationButton
            amount={20}
            selectedAmount={selectedAmount}
            onClick={handleButtonClick}
          />
          <DonationButton
            amount={30}
            selectedAmount={selectedAmount}
            onClick={handleButtonClick}
          />
          <DonationButton
            amount={40}
            selectedAmount={selectedAmount}
            onClick={handleButtonClick}
          />
          <DonationButton
            amount={50}
            selectedAmount={selectedAmount}
            onClick={handleButtonClick}
          />
        </div>
        <div className="mt-4 flex justify-center">
          <input
            type="number"
            placeholder="Custom amount"
            value={customAmount}
            onChange={handleCustomInputChange}
            className="redHatSemiBold min-h-fit h-fit px-[15px] py-[10px] text-black border border-[#DDDDDD] shadow-lg rounded-xl"
          />
          <Button
            onClick={handleCustomAmountSubmit}
            // onTouchStart={handleCustomAmountSubmit}
            className="redHatSemiBold min-h-fit h-fit px-[15px] sm:px-[25px] py-[10px] bg-[#ed143d] text-white border border-[#DDDDDD] shadow-lg rounded-md ml-2"
            isLoading={loading}
            isDisabled={!selectedAmount && customAmount <= 0}
          >
            Donate
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DonationForm;
